// Create a file named TableSelectionContext.js
import React, { createContext, useContext, useState } from "react";

const TableSelectionContext = createContext();

export const useTableSelection = () => useContext(TableSelectionContext);

export const TableSelectionProvider = ({ children }) => {
  const [selections, setSelections] = useState({
    base: null,
    cubierta: null,
    woodOption: null,
    placa: null,
    tamano: null,
    // Add other selections as needed
  });

  return (
    <TableSelectionContext.Provider value={{ selections, setSelections }}>
      {children}
    </TableSelectionContext.Provider>
  );
};
