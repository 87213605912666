exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-aviso-js": () => import("./../../../src/pages/aviso.js" /* webpackChunkName: "component---src-pages-aviso-js" */),
  "component---src-pages-cotizador-js": () => import("./../../../src/pages/cotizador.js" /* webpackChunkName: "component---src-pages-cotizador-js" */),
  "component---src-pages-designer-js": () => import("./../../../src/pages/designer.js" /* webpackChunkName: "component---src-pages-designer-js" */),
  "component---src-pages-example-js": () => import("./../../../src/pages/example.js" /* webpackChunkName: "component---src-pages-example-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-invoice-maker-js": () => import("./../../../src/pages/invoice-maker.js" /* webpackChunkName: "component---src-pages-invoice-maker-js" */),
  "component---src-pages-sillas-js": () => import("./../../../src/pages/sillas.js" /* webpackChunkName: "component---src-pages-sillas-js" */)
}

