import ReactGA from "react-ga";
// gatsby-browser.js and gatsby-ssr.js
import React from "react";
import { TableSelectionProvider } from "./src/context/TableSelectionContext";

export const wrapRootElement = ({ element }) => (
  <TableSelectionProvider>{element}</TableSelectionProvider>
);
export const onClientEntry = () => {
  ReactGA.initialize("G-7RHTQTMF1R"); // Replace with your own Google Analytics tracking ID
};
